<template>
  <div class="table-user-management">
    <div class="bottom">
      <div class="row" v-if="users.length">
        <q-table
          :data="isSearch ? search : users"
          :columns="columns"
          :hide-bottom="true"
          :sort-method="customSort"
          :pagination.sync="pagination"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              @click="$router.push(`/user/${props.row.userId}`)"
            >
              <q-td key="name" :props="props">
                {{ props.row.full }}
              </q-td>
              <q-td key="department" :props="props">
                <span>{{ props.row.primaryDepartment.departmentName }}</span>
              </q-td>
              <q-td key="status" :props="props">
                <span :class="['status', props.row.userStatus.toLowerCase()]">
                  {{ props.row.userStatus | translateStatus }} 
                </span>
              </q-td>
              <q-td key="actions" :props="props" class="td-actions">
                <q-btn class="btn-edit" type="button"></q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TableUserManagement',
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: [],
      isSearch: false,
      columns: [
        {
          name: 'name',
          label: 'Nom complet',
          field: 'name',
          sortable: true,
          align: 'left'
        },
        {
          name: 'department',
          label: 'Département',
          field: 'department',
          sortable: true,
          align: 'left'
        },
        {
          name: 'status',
          label: 'Statut',
          field: 'status',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: this.users.length
      }
    }
  },
  created() {
    this.setSearchUser({ message: '' })
  },
  computed: {
    ...mapGetters('users', ['getSearch'])
  },
  methods: {
    ...mapActions('users', ['setSearchUser']),
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'name':
          return this.handlerSort(data, descending, 'full')
        case 'department':
          return this.handlerSort(data, descending, 'primaryDepartment')
        case 'status':
          return this.handlerSort(data, descending, 'userStatus')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        if (sortBy === 'primaryDepartment') {
          return x[sortBy].departmentName.toLowerCase() >
            y[sortBy].departmentName.toLowerCase()
            ? 1
            : x[sortBy].departmentName.toLowerCase() <
              y[sortBy].departmentName.toLowerCase()
            ? -1
            : 0
        }

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  },
  watch: {
    getSearch: function(message) {
      if (message && message.length) {
        this.isSearch = true

        if (this.users.length) {
          this.search = this.users.filter(user => {
            if (
              user.full.toLowerCase().indexOf(message) > -1 ||
              user.primaryDepartment.departmentName
                .toLowerCase()
                .indexOf(message) > -1 ||
              user.userStatus.toLowerCase().indexOf(message) > -1
            ) {
              return user
            }
          })
        }
      } else {
        this.isSearch = false
      }
    }
  },
  filters: {
    translateStatus: function(status) {
      if (status == 'ACTIVE') {
        return 'Actif'
      }
      else {
        return 'Bloqué'
      }
    }
  }
}
</script>
