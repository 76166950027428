<template>
  <div class="user-management">
    <q-page-container>
      <q-page>
        <div class="top">
          <div class="title">Utilisateurs</div>
          <div class="action">
            <q-btn
              class="btn-new"
              icon-right="add"
              label="Ajouter un utilisateur"
              @click="$router.push('/new-user')"
            />
          </div>
        </div>
        <div class="bottom">
          <div v-if="getUsersManagement.length">
            <TableUserManagement :users="getUsersManagement" />
          </div>
          <LoadingOrEmpty v-else :loading="getLoadingUsersManagement" />
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableUserManagement from '@/components/user-management/TableUserManagement.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  name: 'UserManagement',
  components: {
    LoadingOrEmpty,
    TableUserManagement
  },
  created() {
    this.fetchUsersManagement()
  },
  computed: {
    ...mapGetters('users', [
      'getUsersManagement',
      'getLoadingUsersManagement',
      'getErrorUsersManagement'
    ])
  },
  methods: {
    ...mapActions('users', ['fetchUsersManagement']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorUsersManagement: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
